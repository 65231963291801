import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Group,
    List,
    SimpleCell,
    Avatar,
    Spinner,
    Div,
    Select,
    Pagination,
} from '@vkontakte/vkui';
import { Icon28StatisticsOutline } from '@vkontakte/icons';
import { groupConfig } from './config';

const GroupsTable = ({ search }) => {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'members_count', direction: 'descending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [filterTheme, setFilterTheme] = useState('all');

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const groupIds = groupConfig.map(group => group.id);
                const response = await axios.get('/api/groups', {
                    params: { ids: groupIds.join(',') }
                });
                if (Array.isArray(response.data)) {
                    setGroups(response.data);
                } else {
                    setGroups([]);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching groups:', error);
                setLoading(false);
            }
        };

        fetchGroups();
    }, []);

    const sortedGroups = [...groups].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = 'ascending';
        } else {
            direction = 'descending'; // Для новых ключей начнем с нисходящей сортировки
        }
        setSortConfig({ key, direction });
    };

    const calculatePrice = (membersCount, groupId) => {
        if (groupId === 164451889) {
            return 3500;
        }
        const price = membersCount / 1000;
        return price < 50 ? 50 : Math.round(price);
    };

    const formatMembersCount = (membersCount) => {
        if (membersCount >= 1000000) {
            return (membersCount / 1000000).toFixed(1) + ' млн';
        } else if (membersCount >= 1000) {
            return (membersCount / 1000).toFixed(0) + ' тыс';
        }
        return membersCount.toString();
    };

    const filteredGroups = sortedGroups.filter(group => {
        const groupConfigItem = groupConfig.find(g => g.id === group.id);
        const matchesSearch = group.name.toLowerCase().includes(search.toLowerCase());
        const matchesTheme = filterTheme === 'all' || (groupConfigItem && groupConfigItem.theme === filterTheme);
        return matchesSearch && matchesTheme;
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentGroups = filteredGroups.slice(indexOfFirstItem, indexOfLastItem);

    const themes = [...new Set(groupConfig.map(group => group.theme))];

    return (
        <Group>
            {loading ? (
                <Spinner size="large" />
            ) : (
                <>
                    <Div style={{ display: 'flex', justifyContent: 'space-between', padding: '12px 16px', backgroundColor: '#0077FF', color: '#FFFFFF' }}>
                        <span style={{ flex: '1', fontWeight: 'bold' }}></span>
                        <span style={{ flex: '3', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort('name')}>Группа</span>
                        <span style={{ flex: '2', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort('members_count')}>Подп. {sortConfig.key === 'members_count' ? (sortConfig.direction === 'ascending' ? '↓' : '↑') : ''}</span>
                        <span style={{ flex: '2', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort('price')}>Цена {sortConfig.key === 'price' ? (sortConfig.direction === 'descending' ? '↓' : '') : ''}</span>
                        <span style={{ flex: '1', fontWeight: 'bold' }}>Stats</span>
                    </Div>
                    <Div style={{ padding: '12px 16px' }}>
                        <Select
                            placeholder="Фильтр по теме"
                            value={filterTheme}
                            onChange={e => setFilterTheme(e.target.value)}
                            options={[
                                { label: 'Все', value: 'all' },
                                ...themes.map(theme => ({ label: theme, value: theme }))
                            ]}
                        />
                    </Div>
                    <List>
                        {currentGroups.map(group => (
                            <Div key={group.id} style={{ display: 'flex', alignItems: 'center', padding: '12px 16px', borderBottom: '1px solid #e1e3e6' }}>
                                <Avatar
                                    src={group.photo_100}
                                    size={48}
                                    style={{ marginRight: '16px', cursor: 'pointer' }}
                                    onClick={() => window.open(`https://vk.com/public${group.id}`, '_blank')}
                                />
                                <span
                                    style={{ flex: '3', cursor: 'pointer', color: '#3f8ae0' }}
                                    onClick={() => window.open(`https://vk.com/public${group.id}`, '_blank')}
                                >
                                    {group.name}
                                </span>
                                <span style={{ flex: '2' }}>{formatMembersCount(group.members_count)}</span>
                                <span style={{ flex: '2' }}>{calculatePrice(group.members_count, group.id)} руб.</span>
                                <Icon28StatisticsOutline
                                    size="s"
                                    style={{ flex: '1', cursor: 'pointer' }}
                                    onClick={() => window.open(`https://vk.com/stats?gid=${group.id}`, '_blank')}
                                />
                            </Div>
                        ))}
                    </List>
                    <Div style={{ display: 'flex', justifyContent: 'center', padding: '12px 0' }}>
                        <Pagination
                            currentPage={currentPage}
                            siblingCount={1}
                            totalPages={Math.ceil(filteredGroups.length / itemsPerPage)}
                            onChange={(page) => setCurrentPage(page)}
                        />
                    </Div>
                </>
            )}
        </Group>
    );
};

export default GroupsTable;
