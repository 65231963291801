// config.js
export const groupConfig = [
    { id: 164451889, theme: 'Игровой'},
    { id: 146355998, theme: 'Юмор'},
    { id: 167595229, theme: 'Юмор'},
    { id: 160859969, theme: 'Юмор'},
    { id: 61744518, theme: 'Юмор'},
    { id: 123400772, theme: 'Юмор'},
    { id: 98301621, theme: 'Юмор'},
    { id: 80528763, theme: 'Юмор'},
    { id: 147555498, theme: 'Юмор'},
    { id: 198982846, theme: 'Юмор'},
    { id: 152909367, theme: 'Юмор'},
    { id: 94918654, theme: 'Анкеты'},
    { id: 203178829, theme: 'Юмор'},
    { id: 93735421, theme: 'Анкеты'},
    { id: 83337881, theme: 'Юмор'},
    { id: 54319319, theme: 'Юмор'},
    { id: 169729973, theme: 'Юмор'},
    { id: 96644793, theme: 'Юмор'},
    { id: 99634880, theme: 'Анкеты'},
    { id: 97129549, theme: 'Юмор'},
    { id: 108531402, theme: 'Юмор'},
    { id: 44680693, theme: 'Анкеты'},
    { id: 195941389, theme: 'Цитаты'},
    { id: 218933934, theme: 'Анкеты'},
    { id: 142444693, theme: 'Анкеты'},
    { id: 136364744, theme: 'Юмор'},
    { id: 142256059, theme: 'Юмор'},
    { id: 209847888, theme: 'Цитаты'},
    { id: 194287092, theme: 'Цитаты'},
    { id: 117214477, theme: 'Юмор'},
    { id: 106349610, theme: 'Юмор'},
    { id: 140656331, theme: 'Юмор'},
    { id: 176449226, theme: 'Цитаты'},
    { id: 161405371, theme: 'Цитаты'},
    { id: 190623698, theme: 'Видео'},
    { id: 192248306, theme: 'Цитаты'},
    { id: 174469869, theme: 'Юмор'},
    { id: 175690733, theme: 'Анкеты'},
    { id: 161692375, theme: 'Видео'},
    { id: 59022883, theme: 'Анкеты'},
    { id: 201532998, theme: 'Юмор'},
    { id: 189617441, theme: 'Анкеты'},
    { id: 138161121, theme: 'Видео'},
    { id: 181947182, theme: 'Юмор'},
    { id: 201927997, theme: 'Юмор'},
    { id: 192959178, theme: 'Юмор'},
    { id: 182054564, theme: 'Юмор'},
    { id: 170428369, theme: 'Юмор'},
    { id: 201394498, theme: 'Юмор'},
    { id: 191794126, theme: 'Юмор'},
    { id: 160524650, theme: 'Анкеты'},
    { id: 163400857, theme: 'Юмор'},
    { id: 132503853, theme: 'Юмор'},
    { id: 199210322, theme: 'Юмор'},
    { id: 212661442, theme: 'Юмор'},
    { id: 172096709, theme: 'Юмор'},
    { id: 196672262, theme: 'Анкеты'},
    { id: 198045594, theme: 'Юмор'},
    { id: 138994200, theme: 'Юмор'},
    { id: 211832573, theme: 'Юмор'},
    { id: 74113278, theme: 'Юмор'},
    { id: 80090796, theme: 'Юмор'},
    { id: 172539676, theme: 'Юмор'},
    { id: 194639534, theme: 'Юмор'},
    { id: 138198703, theme: 'Юмор'},
    { id: 156046117, theme: 'Юмор'},
    { id: 85681816, theme: 'Юмор'},
    { id: 162657469, theme: 'Юмор'},
    { id: 155802640, theme: 'Юмор'},
    { id: 86863611, theme: 'Юмор'},
    { id: 159640543, theme: 'Юмор'},
    { id: 207000310, theme: 'Юмор'},
    { id: 177279016, theme: 'Анкеты'},
    { id: 97708118, theme: 'Юмор'},
    { id: 201783013, theme: 'Юмор'},
    { id: 149742710, theme: 'Юмор'},
    { id: 197921590, theme: 'Анкеты'},
    { id: 193797968, theme: 'Юмор'},
    { id: 191920030, theme: 'Юмор'},
    { id: 97462079, theme: 'Юмор'},
    { id: 215275113, theme: 'Юмор'},
    { id: 186449610, theme: 'Юмор'},
    { id: 207088885, theme: 'Юмор'},
    { id: 201743980, theme: 'Юмор'},
    { id: 188334905, theme: 'Анкеты'},
    { id: 195149758, theme: 'Юмор'},
    { id: 140622444, theme: 'Юмор'},
    { id: 195199286, theme: 'Юмор'},
    { id: 200666579, theme: 'Анкеты'},
    { id: 199140226, theme: 'Юмор'},
    { id: 176644700, theme: 'Юмор'},
    { id: 198071536, theme: 'Юмор'},
    { id: 186770671, theme: 'Юмор'},
    { id: 202117553, theme: 'Юмор'},
    { id: 162912722, theme: 'Анкеты'},
    { id: 198626112, theme: 'Юмор'},
    { id: 196134111, theme: 'Юмор'},
    { id: 154021092, theme: 'Юмор'},
    { id: 182633507, theme: 'Юмор'},
    { id: 178379421, theme: 'Юмор'},
    { id: 192528650, theme: 'Цитаты'},
    { id: 89720778, theme: 'Юмор'},
    { id: 203649644, theme: 'Анкеты'},
    { id: 160561510, theme: 'Юмор'},
    { id: 142802541, theme: 'Анкеты'},
    { id: 178913291, theme: 'Юмор'},
    { id: 189382274, theme: 'Юмор'},
    { id: 180282105, theme: 'Юмор'},
    { id: 202754188, theme: 'Юмор'},
    { id: 147776230, theme: 'Юмор'},
    { id: 209146095, theme: 'Анкеты'},
    { id: 205838167, theme: 'Юмор'},
    { id: 202457696, theme: 'Юмор'},
    { id: 194272002, theme: 'Юмор'},
    { id: 164305903, theme: 'Юмор'},
    { id: 101541580, theme: 'Юмор'},
    { id: 194970324, theme: 'Юмор'},
    { id: 148958446, theme: 'Юмор'},
    { id: 199620509, theme: 'Анкеты'},
    { id: 182674192, theme: 'Юмор'},
    { id: 177322730, theme: 'Анкеты'},
    { id: 200474910, theme: 'Юмор'},
    { id: 203922228, theme: 'Юмор'},
    { id: 167879900, theme: 'Юмор'},
    { id: 204726001, theme: 'Юмор'},
    { id: 113775759, theme: 'Юмор'},
    { id: 204957978, theme: 'Юмор'},
    { id: 197001770, theme: 'Юмор'},
    { id: 195051512, theme: 'Юмор'},
    { id: 200507132, theme: 'Анкеты'},
    { id: 202810915, theme: 'Юмор'},
    { id: 178516678, theme: 'Цитаты'},
    { id: 187737827, theme: 'Анкеты'},
    { id: 150525957, theme: 'Юмор'},
    { id: 185317596, theme: 'Анкеты'},
    { id: 90961670, theme: 'Юмор'},
    { id: 203793880, theme: 'Юмор'},
    { id: 217849546, theme: 'Цитаты'},
    { id: 176164051, theme: 'Анкеты'},
    { id: 208762817, theme: 'Юмор'},
    { id: 165851629, theme: 'Анкеты'},
    { id: 168769123, theme: 'Анкеты'},
    { id: 212719100, theme: 'Юмор'},
    { id: 200847093, theme: 'Животные'},
    { id: 103918081, theme: 'Юмор'},
    { id: 171058916, theme: 'Анкеты'},
    { id: 157447839, theme: 'Юмор'},
    { id: 205693796, theme: 'Анкеты'},
    { id: 149592299, theme: 'Юмор'},
    { id: 214308486, theme: 'Юмор'},
    { id: 140092184, theme: 'Юмор'},
    { id: 72655996, theme: 'Юмор'},
    { id: 122159973, theme: 'Юмор'},
    { id: 136219187, theme: 'Юмор'},
    { id: 222321587, theme: 'Юмор'},
    { id: 136438911, theme: 'Юмор'},
    { id: 135210433, theme: 'Юмор'},
    { id: 141545434, theme: 'Юмор'},
    { id: 134435592, theme: 'Юмор'},
    { id: 74956075, theme: 'Юмор'},
    { id: 74311008, theme: 'Юмор'},
    { id: 120578240, theme: 'Юмор'},
    { id: 134352648, theme: 'Юмор'},
    { id: 208257451, theme: 'Юмор'},
    { id: 147987463, theme: 'Юмор'},
    { id: 147355772, theme: 'Юмор'},
    { id: 151336239, theme: 'Юмор'},
    { id: 222383591, theme: 'Юмор'},
    { id: 164663143, theme: 'Юмор'},
    { id: 210682610, theme: 'Юмор'},
    { id: 155492502, theme: 'Юмор'},
    { id: 141307824, theme: 'Юмор'},
    { id: 181059138, theme: 'Юмор'},
    { id: 142505208, theme: 'Юмор'},
    { id: 167187451, theme: 'Юмор'},
    { id: 165646383, theme: 'Юмор'},
    { id: 209710246, theme: 'Юмор'},
    { id: 181215315, theme: 'Юмор'},
    { id: 205410788, theme: 'Юмор'},
    { id: 100174482, theme: 'Юмор'},
    { id: 100174482, theme: 'Юмор'}
];
