import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    AdaptivityProvider,
    AppRoot,
    ConfigProvider,
    SplitLayout,
    SplitCol,
    View,
    Panel,
    PanelHeader,
    Search,
    Spinner,
    Footer, Link
} from '@vkontakte/vkui';
import GroupsTable from './components/GroupsTable';
import '@vkontakte/vkui/dist/vkui.css';
import {Icon24ExternalLinkOutline} from "@vkontakte/icons";

const App = () => {
    const [search, setSearch] = useState('');
    const [groupIds, setGroupIds] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchGroupIds = async () => {
            try {
                const response = await axios.get('/api/groupIds');
                setGroupIds(response.data);
                setLoading(false);
                console.log('Successful fetch');
            } catch (error) {
                console.error('Error fetching group IDs:', error);
                setLoading(false);
            }
        };

        fetchGroupIds();
    }, []);

    return (
        <ConfigProvider>
            <AdaptivityProvider>
                <AppRoot>
                    <SplitLayout>
                        <SplitCol>
                            <View activePanel="main">
                                <Panel id="main">
                                    <PanelHeader>HTML ADVERT</PanelHeader>
                                    <Search value={search} onChange={(e) => setSearch(e.target.value)} />
                                    {loading ? <Spinner size="large" /> : <GroupsTable groupIds={groupIds} search={search} />}
                                    <Footer>

                                        <Link href="https://vk.me/htmladv" target="_blank">
                                            Заказать рекламу
                                        </Link>

                                    </Footer>
                                </Panel>
                            </View>
                        </SplitCol>
                    </SplitLayout>
                </AppRoot>
            </AdaptivityProvider>
        </ConfigProvider>
    );
};

export default App;
